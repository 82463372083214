import Geocode from 'react-geocode';
import {environment} from '../environments/environment';
import { StadiumCoordinates } from '../types/game';
import { STADIUM_PRESENCE_RADIUS_IN_KM } from '../constants/Global.constants';

Geocode.setApiKey(environment.geocodingKey || '');
Geocode.setLanguage('en');
// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType('ROOFTOP');
// Enable or disable logs. Its optional.
Geocode.enableDebug();

type Props = {
  latitude: string;
  longitude: string;
};

export type GeocodeData = {
  latitude: string | null;
  longitude: string | null;
  country: string | null;
};

export function getGeocodeCountry({
  latitude,
  longitude,
}: Props): Promise<string | null> {
  // Get address from latitude & longitude.
  return Geocode.fromLatLng(latitude, longitude).then(
    response => {
      let country;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'country':
              country = response.results[0].address_components[i].long_name;
              break;
          }
        }
      }
      return country;
    },
    error => {
      console.error(error);
      return null;
    },
  )
  .catch(e => {
    console.error(e);
    return null;
  });
}

// From https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
// Probably far more accurate of a calculation than we need but it didn't make sense to put in extra effort to find a less accurate solution
export function getDistanceBetweenCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
  const deg2Rad = (deg: number) => {
    return deg * Math.PI / 180;
  }

  const r = 6371; // Radius of the earth in km
  const dLat = deg2Rad(lat2 - lat1);
  const dLon = deg2Rad(lon2 - lon1);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2Rad(lat1)) * Math.cos(deg2Rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return r * c; // Distance in km
}

export function checkIsInStadium(geocodeData: GeocodeData, stadiumCoordinates: StadiumCoordinates, isHomeCheck: boolean) {
  try {
    const stadiumLat = Number(isHomeCheck ? stadiumCoordinates.homeStadiumLat : stadiumCoordinates.awayStadiumLat);
    const stadiumLong = Number(isHomeCheck ? stadiumCoordinates.homeStadiumLong : stadiumCoordinates.awayStadiumLong);

    const userLat = Number(geocodeData.latitude);
    const userLong = Number(geocodeData.longitude);

    return getDistanceBetweenCoordinates(stadiumLat, stadiumLong, userLat, userLong) <= STADIUM_PRESENCE_RADIUS_IN_KM;
  } catch(e) {
    console.error('Cannot determine stadium presence');
    return false; // TODO: determine if this is how this case should be handled
  }
};
