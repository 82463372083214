import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { RootState } from "../redux/rootReducer";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import routes, { PRE_LOGIN_ROUTES } from "../constants/routes.constants";
import styled from "@emotion/styled";
import { domainMap, domainMapDefault, environment } from '../environments/environment';
import { domainMapT } from '../types/domainMap';
import { getTeamGameSchedule, getTeams, setActiveAppTeamId } from '../redux/modules/teams';
import { clearUser, getGuestUser, getUser } from '../redux/modules/user';
import { resetGameState } from '../redux/modules/game';
import { clearUserCookies, getAuthToken, getUserId } from '../utils/cookies';
import { getBootstrapMedia } from '../redux/modules/media';
import DefaultLayout from '../components/layouts/DefaultLayout';
import errorMessages from '../constants/errorMessages';
import useToast from '../hooks/useToast';
import RadioPlayer from '../components/media/radio/RadioPlayer';
import { setRadioStreamUrl } from '../redux/modules/radio';
import useQuery from '../hooks/useQuery';

//comment

type Props = {
  children?: React.ReactNode;
};

const DimContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
`;

const LoadingOverlay = ({children}: Props) => {
  return (
    <>
      <DimContainer>
        <TailSpin color='#00BFFF' />
      </DimContainer>
      {children}
    </>
  );
};

const AppInitWrapper = ({children}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {search, pathname} = useLocation();
  const query = useQuery(search);
  const toast = useToast();
  const activeTeamIdFromUrl = query.get('activeTeamId');
  const [userSessionExpired, setUserSessionExpired] = useState<boolean>(false);
  const {geocodingData, geocodingError, isInHomeStadium} = useSelector(
    (state: RootState) => state.geolocation,
  );
  const {
    loading: teamsLoading,
    error: teamsError,
    loadingNextGame,
    nextGame,
    teamsData,
    activeTeam,
    loadingNextGameError,
    activeTeamError,
    activeAppTeamId: activeTeamIdFromStore,
  } = useSelector((state: RootState) => state.teams);
  const {
    loading: mediaLoading,
    error: mediaError,
    mediaData
  } = useSelector((state: RootState) => state.media);
  const {
    loading: userLoading,
    userData,
    getUserError,
  } = useSelector((state: RootState) => state.user);

  const {
    streamUrl
  } = useSelector((state: RootState) => state.radio);

  const isReadyToRender = useCallback(() => {
    console.log(`isReadyToRender\nactiveTeamIdFromStore=${activeTeamIdFromStore}\nteamsLoading=${teamsLoading}\nmediaLoading=${mediaLoading}\nuserLoading=${userLoading}\ngetUserError=${getUserError}`);
    console.log('userData');
    console.log(userData);
    console.log('activeTeam');
    console.log(activeTeam);
    console.log('nextGame');
    console.log(nextGame);
    if (!activeTeamIdFromStore || teamsLoading || mediaLoading || userLoading || !userData || !activeTeam || !nextGame || (activeTeam && activeTeam.radioUrl && !streamUrl && !getUserError)) {
      console.log(`not ready to render`);
      return false;
    }
    console.log(`ready to render`);
    return true;
  }, [activeTeamIdFromStore, teamsLoading, mediaLoading, userLoading, userData, activeTeam, nextGame, streamUrl, getUserError]);

  const generateCacheBustedUrl = (sourceUrl: string): string => {
    if (environment.appVersion) {
      return `${sourceUrl}?v=${environment.appVersion.replaceAll(".","")}`;
    }
    return sourceUrl;
  }


  useEffect(() => {
    //TODO: Why does this happen on initial load with no data.....
    if (userSessionExpired) {
      toast.statusToast(errorMessages.userExpiredError, 'error');
      setUserSessionExpired(false);
    }
  },[toast, userSessionExpired]);



  useEffect(() => {
    const hasExistingUser = !!getAuthToken() && !!getUserId();
    if (!hasExistingUser || getUserError) {
      if (hasExistingUser) {
        setUserSessionExpired(true);
      }
      clearUserCookies();
      dispatch(clearUser());
      dispatch(resetGameState());
    } else {
      dispatch(getUser() as unknown as AnyAction);
    }
  }, [dispatch, getUserError]);

  useEffect(() => {
    let activeTeamIdFromDomain: string | null | undefined;
    const faviconUpdate = (domainMap: domainMapT) => {
      //grab favicon element by ID
      const faviconLg = document.getElementById("favicon-lg") as HTMLLinkElement;
      const faviconSm = document.getElementById("favicon-sm") as HTMLLinkElement;
      const faviconApple = document.getElementById("favicon-apple") as HTMLLinkElement;
      const startupImageApple = document.getElementById("startupimage-apple") as HTMLLinkElement;
      const manifest = document.getElementById("manifest") as HTMLLinkElement;
      const appleTitle = document.getElementById("apple-mobile-title") as HTMLMetaElement;
      const applicationName = document.getElementById("application-name") as HTMLMetaElement;
      const mask = document.getElementById("mask") as HTMLLinkElement;

      if (faviconLg) {
        faviconLg.href = generateCacheBustedUrl(domainMap.faviconLgPath || domainMapDefault.faviconLgPath);
      }
      if (faviconSm) {
        faviconSm.href = generateCacheBustedUrl(domainMap.faviconSmPath || domainMapDefault.faviconSmPath);
      }
      if (faviconApple) {
        faviconApple.href = generateCacheBustedUrl(domainMap.appleTouchPath || domainMapDefault.appleTouchPath);
      }
      if (startupImageApple) {
        startupImageApple.href = generateCacheBustedUrl(domainMap.appleTouchPath || domainMapDefault.appleTouchPath);
      }

      if (mask) {
        mask.href = generateCacheBustedUrl(domainMap.maskPath || domainMapDefault.maskPath);
        // @ts-ignore-next-line
        mask.color = domainMap.maskColor || domainMapDefault.maskColor;
      }

      if (manifest) {
        manifest.href = generateCacheBustedUrl(domainMap.manifestPath || domainMapDefault.manifestPath);
      }
      if (appleTitle) {
        appleTitle.content = domainMap.title || domainMapDefault.title;
      }
      if (applicationName) {
        applicationName.content = domainMap.title || domainMapDefault.title;
      }
    };

    const domainParts = window.location.hostname.split('.'); // will be length 2 or 3
    const domain = domainParts.slice(domainParts.length === 2 ? 0 : 1).join('.');
    const matchDomain = domainMap.find((d) => d.domain === domain);
    if (matchDomain) {
      document.title = matchDomain.title || domainMapDefault.title;
      //run our function here
      faviconUpdate(matchDomain);
      activeTeamIdFromDomain = matchDomain.teamId;
      if (
        activeTeamIdFromDomain &&
        activeTeamIdFromDomain !== activeTeamIdFromStore
      ) {
        dispatch(setActiveAppTeamId(activeTeamIdFromDomain));
      } else if (
        activeTeamIdFromUrl &&
        activeTeamIdFromUrl !== activeTeamIdFromStore
      ) {
        dispatch(setActiveAppTeamId(activeTeamIdFromUrl));
      }
      if (!(activeTeamIdFromDomain || activeTeamIdFromUrl || activeTeamIdFromStore)) {
        navigate(routes.missingTeam);
      }
    }
  }, [activeTeamIdFromStore, activeTeamIdFromUrl, dispatch, navigate]);


  useEffect(() => {
    if (activeTeamIdFromStore && !teamsLoading && !teamsData.length) {
      dispatch(getTeams(activeTeamIdFromStore) as unknown as AnyAction);
    }
  }, [activeTeamIdFromStore, dispatch, teamsData.length, teamsLoading]);

  useEffect( () => {
    console.log(`determining if next game can be loaded loadingNextGame=${loadingNextGame} nextGame=${nextGame}`);
    if (activeTeamIdFromStore && !nextGame ) {
      console.log('calling getTeamGameSchedule');
      dispatch(getTeamGameSchedule() as unknown as AnyAction);
    }
  }, [activeTeamIdFromStore, dispatch, loadingNextGame, nextGame])

  useEffect(() => {
    if (activeTeamIdFromStore && !mediaLoading && !mediaData) {
      dispatch(getBootstrapMedia(
        activeTeamIdFromStore,
      ) as unknown as AnyAction);
    }
  }, [activeTeamIdFromStore, dispatch, mediaData, mediaLoading]);

  useEffect(() => {
    if (activeTeamIdFromStore && !userLoading && !userData) {
      dispatch(getGuestUser(activeTeamIdFromStore) as unknown as AnyAction);
    }
  }, [activeTeamIdFromStore, dispatch, userData, userLoading]);

  useEffect(() => {
    if (activeTeam && activeTeam.radioUrl) {
      dispatch(setRadioStreamUrl(activeTeam.radioUrl));
    }
  }, [activeTeam, dispatch]);


  useEffect(() => {
    if (userData?.isNewUser && !userData?.guestUser && !PRE_LOGIN_ROUTES.includes(pathname) && nextGame) {
      navigate(routes.accountSetup.replace(':gameId', nextGame.id));
    }
  },[navigate, nextGame, pathname, userData?.guestUser, userData?.isNewUser]);

  useEffect(() => {
    if (!PRE_LOGIN_ROUTES.includes(pathname) && (teamsError || loadingNextGameError || activeTeamError || mediaError)) {
      const error = 'There was an error loading game data. Please try again later.';
      navigate(`${routes.preLoginError}?error=${encodeURIComponent(error)}`);
    }
  },[activeTeamError, loadingNextGameError, mediaError, navigate, pathname, teamsError]);


  useEffect(() => {
    if (!PRE_LOGIN_ROUTES.includes(pathname)) {
      if (geocodingData && !isInHomeStadium  && !!activeTeam) {
        let error = `This experience is only available at the ${activeTeam.homeStadiumName || 'stadium/arena'}. Please join us for a game soon!`;
        if (activeTeam.teamNickname) {
          error = `Sorry, the ${activeTeam.teamNickname} experience is only available at ${activeTeam.homeStadiumName || 'stadium/arena'}. Please join us for a game soon!`;
        }
        navigate(`${routes.preLoginError}?error=${encodeURIComponent(error)}`);
      }

      if (geocodingError) {
        navigate(`${routes.preLoginError}?error=${encodeURIComponent('We are unable to determine your location. Please be sure your location services are turned on via the settings for your browser.')}`);
      }

      if (!!geocodingData && geocodingData.country !== 'United States' &&
        geocodingData.country !== 'Canada') {
        navigate(routes.ineligibleLocation);
      }
    }
  },[activeTeam, geocodingData, geocodingError, isInHomeStadium, navigate, pathname])

  return (
    <>
      {!!streamUrl && <RadioPlayer />}
      {(isReadyToRender() || pathname === routes.preLoginError || pathname === routes.ineligibleLocation) ? children :
        <DefaultLayout>
          <LoadingOverlay/>
        </DefaultLayout>
      }
    </>
  )
};

export default AppInitWrapper;
